import React from 'react';
import { Route } from 'react-router-dom';
import ErrorPageNotFound from '../pages/error/PageNotFound';
import PrivateRoute from './PrivateRoute';
import DefaultRoute from './DefaultRoute';
import MergeCustomers from '../pages/customer/MergeCustomers';

// lazy load all the views
const Register = React.lazy(() => import('../pages/account/Register'));
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const ForgotPassword = React.lazy(() => import('../pages/account/ForgotPassword'));
const SetPassword = React.lazy(() => import('../pages/account/SetPassword'));
const VerifyEmail = React.lazy(() => import('../pages/account/VerifyEmail'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

// dispatcher routes
const Dashboard = React.lazy(() => import('../custom/Dashboard'));
const EditableTable_New = React.lazy(() => import('../custom/EditableTabel_3'));
const AllTrips = React.lazy(() => import('../custom/AllTrips'));
const TripDetail = React.lazy(() => import('../custom/TripDetail'));
const AddNewForm = React.lazy(() => import('../custom/AddNewForm'));
const DriverView = React.lazy(() => import('../custom/DriverView'));
const Customers = React.lazy(() => import('../pages/customer/Customers'));
const Billing = React.lazy(() => import('../pages/billing/Billing'));
const Zones = React.lazy(() => import('../custom/Zones'));
const CustomerList = React.lazy(() => import('../pages/customer/CustomerList'));
const DriverList = React.lazy(() => import('../pages/driver/DriverList'));
const DriverDetails = React.lazy(() => import('../pages/driver/DriverDetails'));

// customer routes
const CustomerDashboard = React.lazy(() => import('../customers/CustomerDashboard'));
const Addresses = React.lazy(() => import('../customers/SavedAddresses'));
const PaymentMethods = React.lazy(() => import('../customers/PaymentMethods'));
const Invoices = React.lazy(() => import('../customers/Invoices'));
const Settings = React.lazy(() => import('../customers/Settings'));


// auth
const authRoutes = [
    {
        path: '/account/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/account/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
    {
        path: '/account/register',
        name: 'Register',
        component: Register,
        route: Route,
        exact: true,
    },
    {
        path: '/account/forgotpassword',
        name: 'Forgot Password',
        component: ForgotPassword,
        route: Route,
    },
    {
        path: '/account/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/account/setpassword',
        name: 'Set Password',
        component: SetPassword,
        route: Route,
        exact: true,
    },
    {
        path: '/verify-email',
        name: 'Verify Email',
        component: VerifyEmail,
        route: Route,
    },
    {
        path: '/404',
        name: 'Not Found',
        component: ErrorPageNotFound,
        route: Route,
    },
];

const myRoutes = [
    {
        path: '/',
        exact: true,
        component: DefaultRoute,
        route: PrivateRoute,
        roles: ['Dispatcher', 'Customer'],
    },
    {
        path: '/dispatch',
        name: 'Dispatch',
        component: EditableTable_New,
        route: PrivateRoute,
        roles: ['Dispatcher'],
    },
    {
        path: '/trips',
        name: 'Trips',
        component: AllTrips,
        route: PrivateRoute,
        roles: ['Dispatcher'],
    },
    {
        path: '/tripdetail',
        name: 'Trip Detail',
        component: TripDetail,
        route: PrivateRoute,
        roles: ['Dispatcher'],
    },
    {
        path: '/drivers',
        name: 'DriversList',
        component: DriverList,
        route: PrivateRoute,
        module: 'drivers',
        exact: true,
        roles: ['Dispatcher'],
    },
    {
        path: '/customers',
        name: 'Customers',
        component: CustomerList,
        route: PrivateRoute,
        exact: true,
        roles: ['Dispatcher'],
    },
    {
        path: '/customer/:id',
        name: 'Customer Details',
        component: Customers,
        route: PrivateRoute,
        module: 'customers',
        roles: ['Dispatcher'],
    },
    // {
    //     path: '/driver/:id',
    //     name: 'Driver Details',
    //     component: Drivers,
    //     route: PrivateRoute,
    //     module: 'drivers',
    //     roles: ['Dispatcher'],
    // },
    {
        path: '/drivers/new',
        name: 'New Driver',
        component: AddNewForm,
        route: PrivateRoute,
        module: 'drivers',
        exact: true,
        roles: ['Dispatcher'],
    },
    {
        path: '/drivers/:id',
        name: 'Driver Details',
        component: DriverDetails,
        route: PrivateRoute,
        module: 'drivers',
        roles: ['Dispatcher'],
    },

    {
        path: '/customers/new',
        name: 'New Customer',
        component: AddNewForm,
        route: PrivateRoute,
        module: 'customers',
        exact: true,
        roles: ['Dispatcher'],
    },

    {
        path: '/driver-earnings',
        name: 'Drivers Earnings',
        component: DriverView,
        route: PrivateRoute,
        exact: true,
        roles: ['Dispatcher'],
    },
    {
        path: '/billing',
        name: 'Billing',
        component: Billing,
        route: PrivateRoute,
        roles: ['Dispatcher'],
    },
    {
        path: '/customerdashboard',
        name: 'Customer Dashboard',
        component: CustomerDashboard,
        route: PrivateRoute,
        exact: true,
        roles: ['Customer'],
    },
    {
        path: '/savedaddresses',
        name: 'addresses',
        component: Addresses,
        route: PrivateRoute,
        roles: ['Customer'],
    },
    {
        path: '/settings',
        name: 'zones',
        component: Zones,
        route: PrivateRoute,
        roles: ['Dispatcher'],
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        route: PrivateRoute,
        roles: ['Dispatcher'],
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: Invoices,
        route: PrivateRoute,
        roles: ['Customer'],
    },
    {
        path: '/paymentmethods',
        name: 'Payment Methods Page',
        component: PaymentMethods,
        route: PrivateRoute,
        roles: ['Customer'],
    },
    {
        path: '/accountsettings',
        name: 'Account',
        component: Settings,
        route: PrivateRoute,
        roles: ['Customer'],
    },
    {
        path: '/mergeCustomer',
        name: 'Merge Customer',
        component: MergeCustomers,
        route: PrivateRoute,
        roles: ['Dispatcher'],
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
//const authProtectedRoutes = [rootRoute, dashboardRoutes, ...appRoutes, pageRoutes, uiRoutes];
//const publicRoutes = [...authRoutes, ...otherPublicRoutes];
const authProtectedRoutes = [...myRoutes];
const publicRoutes = [...authRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
