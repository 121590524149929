import { phone } from 'phone';
//via Stack Overflow: https://stackoverflow.com/a/56253298
function flattenObj(obj, parent, res = {}) {
    for (let key in obj) {
        let propName = parent ? parent + '_' + key : key;
        if (typeof obj[key] == 'object') {
            flattenObj(obj[key], propName, res);
        } else {
            res[propName] = obj[key];
        }
    }
    return res;
};

function formatData(type, data) {
    switch (type) {
        case 'date':
            if (!data) data = new Date();
            return new Intl.DateTimeFormat('en-US', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }).format(new Date(data));
        case 'formal-date':
            if (!data) data = new Date();
            else data = new Date(data);
            return data.toISOString().substring(0, 10);
        case 'phone':
            let newStr = '(' + data.substring(0, 3) + ') ' + data.substring(3, 6) + '-' + data.substring(6, 10)
            return newStr;
        case 'currency':
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(data || 0);
        default:
            return data;
    }
}

//courtesy of: http://blog.nicohaemhouts.com/2015/08/03/accessing-nested-javascript-objects-with-string-key/
function getNestedValue(theObject, path, separator) {
    try {
        separator = separator ?? '.';
        return path
            .split(separator)
            .reduce(function (obj, property) {
                return obj[property];
            }, theObject);

    } catch (err) {
        return undefined;
    }
}

const sortByName = (a, b) => {
    const valueA = a.fullName || a.customer?.fullName || '';
    const valueB = b.fullName || b.customer?.fullName || '';
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}

const formatPhoneNumber = (phoneNumber) => {
    var cleaned = (phoneNumber?.replace(/^1/, '').replace(/^\+1/, ''));
    var match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return cleaned;
}

const validatePhoneNumber = (phoneNumber) => {
    const phoneObject = phone(phoneNumber, { country: 'USA' });
    return phoneObject.phoneNumber;
}

const validateTripFields = (data) => {
    if (data.pickupPhone || data.dropoffPhone) {
        const pickupPhone = validatePhoneNumber(data.pickupPhone);
        const dropoffPhone = validatePhoneNumber(data.dropoffPhone);
        data.pickupPhone = pickupPhone;
        data.dropoffPhone = dropoffPhone;

        return { pickupPhone: pickupPhone ?? true, dropoffPhone: dropoffPhone ?? true }
    }
    else return { pickupPhone: true, dropoffPhone: true } // because it is not always required
}

const getHashOfString = (str) => {
    let hash = 0;
    for (let i = 0; i < str?.length; i++) {
        hash = str?.charCodeAt(i) + ((hash << 5) - hash);
    }
    hash = Math.abs(hash);
    return hash;
};
const normalizeHash = (hash, min, max) => {
    return Math.floor((hash % (max - min)) + min);
};

const backgroundGenerator = (name) => {
    const hRange = [0, 360];
    const sRange = [40, 80];
    const lRange = [25, 60];
    let hash, hsl;
    const generateHSL = (name: string): HSL => {
        const hash = getHashOfString(name);
        const h = normalizeHash(hash, hRange[0], hRange[1]);
        const s = normalizeHash(hash, sRange[0], sRange[1]);
        const l = normalizeHash(hash, lRange[0], lRange[1]);
        return [h, s, l];
    };

    const HSLtoString = (hsl: HSL) => {
        return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
    };

    hash = generateHSL(name)
    hsl = HSLtoString(hash)
    return hsl
}


function horizontalWheel(container) {
    /** Max `scrollLeft` value */
    let scrollWidth;

    /** Desired scroll distance per animation frame */
    let getScrollStep = () => scrollWidth / 50 /* ADJUST TO YOUR WISH */;

    /** Target value for `scrollLeft` */
    let targetLeft;

    function scrollLeft() {
        let beforeLeft = container.scrollLeft;
        let wantDx = getScrollStep();
        let diff = targetLeft - container.scrollLeft;
        let dX = wantDx >= Math.abs(diff) ? diff : Math.sign(diff) * wantDx;

        // Performing horizontal scroll
        container?.scrollBy(dX, 0);

        // Break if smaller `diff` instead of `wantDx` was used
        if (dX === diff)
            return;

        // Break if can't scroll anymore or target reached
        if (beforeLeft === container.scrollLeft || container.scrollLeft === targetLeft)
            return;

        requestAnimationFrame(scrollLeft);
    }

    container?.addEventListener('wheel', e => {
        e.preventDefault();

        scrollWidth = container.scrollWidth - container.clientWidth;
        targetLeft = Math.min(scrollWidth, Math.max(0, container.scrollLeft + e.deltaY));

        requestAnimationFrame(scrollLeft);
    });
}

const valueFormatter = (value) => {
    switch (value) {
        case 'cc':
            return 'Credit Card';
        case 'wire':
            return 'Wire Transfer';
        case 'ach':
            return 'ACH';
        case 'check':
            return 'Check';
        case 'zelle':
            return 'Zelle/Quickpay';
        default:
            return 'Other'
    }
}

export { formatData, flattenObj, getNestedValue, sortByName, formatPhoneNumber, validatePhoneNumber, validateTripFields, getHashOfString, normalizeHash, backgroundGenerator, horizontalWheel, valueFormatter };