import React, { useState, useEffect, createContext, useContext } from 'react';
import { UserContext } from './UserContext';
import api from '../helpers/api';

export const CustomerTripContext = createContext();

export default function CustomerTripProvider(props) {
    const [trips, setTrips] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [error, setError] = useState(false);
    const [tripLoading, setTripLoading] = useState(false);

    const { socket } = useContext(UserContext);

    const getData = () => {
        setTripLoading(true);
        api('trips?include[customer]=true&include[tags]=true&sort[orderNumber]=desc&limit=2000')
            .then((res) => {
                setTrips(res);
                setTripLoading(false);
            })
            .catch((err) => {
                //setError(err?.error?.message);
                console.log(err);
                setTripLoading(false);
            });
    };
    
    const getInvoices = () => {
        api(`invoices`)
            .then((res) => {
                setInvoices(res)
            })
            .catch((err) => {
                //setError(err?.error?.message);
                console.log(err);
            });
    };

    useEffect(() => {
        if (error)
            setTimeout(() => setError(false), 3000);
    }, [error]);

    useEffect(() => {
        getData();
        getInvoices()
    }, []);

    useEffect(() => {
        function newTrip(trip) {
            console.log(trips)
            setTrips(t => [trip, ...t])
        }
        // update trip not working
        function update(trip) {
            setTrips(t => {
                const temp = [...t];
                temp[temp.findIndex((e) => e.id === trip.id)] = trip;
                console.log(temp);
                return temp;
            });
        }
        function deleted(trip) {
            setTrips(trips => trips.filter((e) => !trip.includes(e.id)));
        }
        function newInvoice(trip) {
            console.log(trip);
            setInvoices([trip, ...invoices])
        }

        socket.current.on('trip:new', newTrip);
        socket.current.on('trip:update', update);
        socket.current.on('trip:deleted', deleted);
        socket.current.on('invoice:new', newInvoice);

        return () => {
            socket.current.off('trip:new', newTrip);
            socket.current.off('trip:update', update);
            socket.current.off('trip:deleted', deleted);
            socket.current.off('invoice:new', newInvoice);
        };
    }, []);

    return (
        <CustomerTripContext.Provider
            value={{
                trips,
                setTrips,
                invoices,
                tripLoading
            }}>
            {props.children}
        </CustomerTripContext.Provider>
    );
}


