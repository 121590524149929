
//statuses: ['awaiting-driver', 'driver-assigned', 'accepted', 'picked-up', 'dispatched', 'completed', 'cancelled'],
const tripStatuses = {
    'awaitingDriver': 'awaiting_driver',
    'driverAssigned': 'driver_assigned',
    'dispatched': 'dispatched',
    'accepted': 'accepted',
    'pickedUp': 'picked_up',
    'completed': 'completed',
    'cancelled': 'cancelled',
    'adminCancelled': 'admin_cancelled',
    'driverCancelled': 'driver_cancelled',
    'customerCancelled': 'customer_cancelled',
    'scheduled': 'scheduled',
    'onHold': 'on_hold',
}
const statuses = [
    { name: 'Awaiting Driver', values: tripStatuses.awaitingDriver, color: 'warning', sortNum: 1, },
    { name: 'Driver Assigned', values: tripStatuses.driverAssigned, color: 'indigo', sortNum: 2, },
    { name: 'Scheduled', values: tripStatuses.scheduled, color: 'dark', sortNum: 3, },
    { name: 'Dispatched', values: tripStatuses.dispatched, color: 'info', sortNum: 4, },
    { name: 'On Hold', values: tripStatuses.onHold, color: 'dark', sortNum: 5, },
    { name: 'Accepted', values: tripStatuses.accepted, color: 'secondary', sortNum: 6, },
    { name: 'Picked Up', values: tripStatuses.pickedUp, color: 'primary', sortNum: 7, },
    { name: 'Completed', values: tripStatuses.completed, color: 'success', sortNum: 8, },
    { name: 'Cancelled', values: [tripStatuses.cancelled, tripStatuses.adminCancelled, tripStatuses.driverCancelled, tripStatuses.customerCancelled], color: 'danger', sortNum: 9, },
];
const openTripStatuses = [
    tripStatuses.dispatched,
    tripStatuses.accepted,
    tripStatuses.pickedUp,
]
const cancelledTripStatuses = [
    tripStatuses.cancelled,
    tripStatuses.driverCancelled,
    tripStatuses.adminCancelled,
    tripStatuses.customerCancelled,
]
const tripHistoryStatuses = [
    tripStatuses.completed,
    ...cancelledTripStatuses
]
const dispatchedStatuses = [
    tripStatuses.dispatched,
    tripStatuses.completed,
    tripStatuses.accepted,
    tripStatuses.pickedUp
];
//can update to these statuses
const updateableStatuses = [
    tripStatuses.awaitingDriver,
    tripStatuses.driverAssigned,
    tripStatuses.scheduled,
    tripStatuses.accepted,
    tripStatuses.pickedUp,
    tripStatuses.completed,
    tripStatuses.onHold,
]

const paymentStatuses = ['paid', 'unpaid'];
const billingSchedules = [{ value: 'individual', label: 'Individual' }, { value: 'daily', label: 'Daily' }, { value: 'weekly', label: 'Weekly' }, { value: 'monthly', label: 'Monthly' }]
const chargeTypes = [
    // { value: null, label: '--Select payment method--', selected: true },
    { value: 'cc', label: 'Credit Card', selected: true },
    { value: 'ach', label: 'ACH' },
    { value: 'check', label: 'Check' },
    { value: 'zelle', label: 'Zelle/Quickpay' },
    { value: 'wire', label: 'Wire transfer' },
    { value: 'other', label: 'Other' },
    // { value: 'ccOutside', label: 'CC outside of this system'}
];
// const packageTypes = ['Bag', 'Box', 'Envelope', 'Garment', 'Open Wrap', 'Delicate', 'Other'];
const packageTypes = [];
const tags = [];
const settings = [];
const vehicleTypes = [];
const languages = [];


export {
    statuses,
    openTripStatuses,
    tripHistoryStatuses,
    cancelledTripStatuses,
    dispatchedStatuses,
    updateableStatuses,
    tripStatuses,
    paymentStatuses,
    packageTypes,
    tags,
    settings,
    billingSchedules,
    chargeTypes,
    vehicleTypes,
    languages
};
