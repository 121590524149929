import { useContext, useEffect, useMemo, useState } from "react";
import { Card, Button, Form, Table } from 'react-bootstrap';
import { FormInput } from '../../components';
import Swal from "sweetalert2";
import { TABLE_FIELDS } from "../../constants/module_data";
import { CustomerContext } from "../../contexts";
import api from "../../helpers/api";
import { toast } from 'react-toastify';

const MergeCustomers = ({ customerFromRoute }) => {

  const { customers } = useContext(CustomerContext);
  console.log(customers.filter(e => e.user?.lastLogIn && e))

  const [customer_id, setCustomer_id] = useState(customerFromRoute?.id);
  const [customer, setCustomer] = useState(customerFromRoute);
  const [customerToMerge, setCustomerToMerge] = useState(null);
  const [search, setSearch] = useState(null);
  const [searchResult, setSearchResult] = useState(null);

  const customerValue = (c) => {
    return c?.companyName
      || c?.fullName
      || c?.phoneNumber
      || c?.billingEmail
      || c;
  };

  const getResults = () => {
    console.log(customers.length)
    if (!customers.length) return [];
    const fieldsToCompare = ['fullName', 'companyName', 'phoneNumber', 'billingEmail'];

    return customers.filter(e => {
      if (customer.id === e.id) return false;
      let isDuplicate = false;
      for (let field of fieldsToCompare) {
        if (e[field]?.toLowerCase().includes(search.toLowerCase())) {
          isDuplicate = true;
          break;
        }
      }
      return isDuplicate && e;
    })
  }
  const findCustomer = (e) => {
    e.preventDefault();
    if (!customer_id) {
      Swal.fire('Cannot be empty');
      return;
    }
    const c = customers.find(e => e.id == customer_id);
    if (!c) {
      Swal.fire('Not found');
      return;
    } else {
      setCustomer(c);
    }
  }

  const findCustomerToMerge = (e) => {
    e.preventDefault();
    if (!search) {
      Swal.fire('cannot be empty');
      return;
    }
    const c = getResults();
    if (!c.length) {
      Swal.fire('not found');
      return;
    } else setSearchResult(c)
  }

  const clearSelection = () => {
    setCustomerToMerge(null);
    setSearch(null)
    setSearchResult(null)
    if (!customerFromRoute) {
      setCustomer_id(null)
      setCustomer(null)
    }
  }

  const merge = () => {
    if (!customer || !customerToMerge) {
      Swal.fire('please select 2 customers');
      return;
    }
    if (customer.id === customerToMerge.id) {
      Swal.fire('cannot be the same');
      return;
    }
    Swal.fire({
      title: `${customerValue(customerToMerge)} will be deleted and all data will be merged into ${customerValue(customer)}`,
      showDenyButton: true,
      confirmButtonText: 'Proceed',
      denyButtonText: 'Cancel',
      confirmButtonColor: '#78cbf2',
    }).then((result) => {
      if (result.isConfirmed) {
        toast.promise(api('merge-customers', 'POST', {
          customer_id: customer.id,
          customerToMerge_id: customerToMerge.id
        }).then(res => clearSelection()), {
          pending: 'Merging...',
          success: `Successfully merged`,
          error: {
            render({ data }) {
              // When the promise reject, data will contains the error
              return 'Error merging ' + data
            }
          }
        });
      } else {
        clearSelection();
      }
    })
  }

  const Info = ({ data }) => {
    let fields = TABLE_FIELDS['CUSTOMERS_FIELDS'];
    if (data.business) fields = fields.concat(TABLE_FIELDS['ORDER_FIELDS']);
    console.log(data)

    return (
      <Table>
        <tr key={`${data.id}_0`}>
          <td>ID</td>
          <td>{data.id}</td>
        </tr>
        {
          fields.map((field, i) => {
            return <tr key={`${data.id}_${i}`}>
              <td>{field.Header}</td>
              <td>{data[field.accessor]}</td>
            </tr>
          })
        }
        <tr key={`${data.id}_a`}>
          <td>Has User Account</td>
          <td>{data.user ? 'Yes' : 'No'}</td>
        </tr>
        {
          data.user && <tr key={`${data.id}_b`}>
            <td>Last Login</td>
            <td>{data.user.lastLogIn}</td>
          </tr>
        }
        {/* add the following fields:
          - total trips
          - payment methods count
          - invoices count
        */}
      </Table >
    )
  }

  return (
    <Card>
      <div className="mt-3 mb-3" style={{ display: 'flex' }}>
        <div className="ms-3 me-5" style={{ width: '50%' }}>
          {customer ?
            <>
              <h2 className="text-center mb-3">Customer to keep</h2>
              <Info className='w-75' data={customer} />
            </>
            : <>
              <h2 className="text-center mb-3"> Find customer to keep</h2>
              <Form className="text-center" onSubmit={findCustomer}>
                <label>
                  <FormInput type="text" onChange={(e) => setCustomer_id(e.target.value)} placeholder={'Customer ID'} />
                </label>
                <Button type="submit">Preview</Button>
              </Form>
            </>
          }
        </div>
        <div className="ps-4" style={{ width: '50%', borderLeft: '1px solid lightgrey' }}>
          {customerToMerge ? <>
            <h2 className="text-center mb-3">Customer to be merged and deleted</h2>
            <Info data={customerToMerge} />
            <div className="pull-right">
              <Button onClick={merge}>Merge</Button>
              <Button variant='danger' className="mx-2" onClick={clearSelection}>Cancel</Button>
            </div>

          </>
            : searchResult ?
              <>
                <h2 className="text-center mb-3">Choose customer to merge
                  <i className="mdi mdi-refresh ms-2 text-primary" role='button' title='Start over' onClick={() => setSearchResult(null)}></i></h2>
                <Table style={{ width: '50%', margin: 'auto' }} size="sm" >
                  {searchResult.map((e, i) =>
                    <tbody>  <tr key={i}>
                      <td>{customerValue(e)}</td>
                      <td className="pull-right"><Button onClick={() => { setCustomerToMerge(e) }}>select for merge</Button></td>
                    </tr>
                    </tbody>
                  )}
                </Table>
              </>
              :
              <>
                <h2 className="text-center mb-3">Customer to merge</h2>
                <Form className="text-center" onSubmit={findCustomerToMerge}>
                  <label>
                    <FormInput required type="text" onChange={(e) => setSearch(e.target.value)} placeholder={'Search customers...'} />
                  </label>
                  <Button type="submit">Search</Button>
                </Form>
              </>
          }
        </div>
      </div>
    </Card>

  )

}

export default MergeCustomers;