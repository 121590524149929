import React, { useState } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

function TripInfo({ refData, createdFor }) {
  const [tooltipV, setTooltipV] = useState("Copy coordinates");
  const [adrsTooltipVal, setAdrsTooltipVal] = useState("Copy full address");

  let addressPlacholder = createdFor === "pickupAddress" ? "pickupAddress" : "dropoffAddress"

  const copyCoordinates = ({ lat, long }) => {
    setTooltipV("Coordinates copied")
    navigator.clipboard.writeText(`${lat} ${long}`)
  }

  const copyAddress = (e) => {
    setAdrsTooltipVal("Address copied")
    navigator.clipboard.writeText(e)
  }

  const adrsTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {adrsTooltipVal}
    </Tooltip>
  );

  const gpsTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tooltipV}
    </Tooltip>
  );

  return (
    <div className='address-div'>
      <span className='address-sm-text me-1'>Address:</span>
      {(<OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={adrsTooltip}
        onExited={() => setAdrsTooltipVal("Copy full address")}
      ><span role="button"
        onClick={() => copyAddress((refData?.current?.[addressPlacholder]?.formattedAddress) + " "
          + (refData?.current?.[addressPlacholder + "2"] ?? ""))}
        className='address-sm-text text-dark'>{refData?.current?.[addressPlacholder]?.street}
        </span>
      </OverlayTrigger>)}

      <span className='address-sm-text ms-2'>Line 2:
        <span className='text-dark'>{refData.current?.[addressPlacholder + "2"] ?? ""}</span>
      </span><br />

      <span className='address-sm-text'>City:
        <span className='text-dark'>{refData?.current?.[addressPlacholder]?.city}</span>
      </span>

      <span className='address-sm-text'>State:
        <span className='text-dark'>{refData?.current?.[addressPlacholder]?.state}</span>
      </span>

      <span className='address-sm-text'> Zip:
        <span className='text-dark'>{refData?.current?.[addressPlacholder]?.zipCode}</span>
      </span>

      {(<OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={gpsTooltip}
        onExited={() => setTooltipV("Copy coordinates")}
      ><span role="button"
        onClick={() => copyCoordinates(refData?.current?.[addressPlacholder]?.coordinates)}
        className='address-sm-text'>GPS: </span>
      </OverlayTrigger>)}

      <span
        onClick={() => window.open(`https://maps.google.com?q=${encodeURIComponent(refData?.current?.[addressPlacholder]?.formattedAddress)}`)}
        role="button" className='address-sm-text text-dark'>Open in maps</span>
    </div>
  )
}

export default TripInfo;