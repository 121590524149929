import React, { useState, useEffect, createContext, useContext } from 'react';
import { sortByName } from '../utils/functions';
import { UserContext } from './UserContext';

export const CustomerContext = createContext();

export default function CustomerProvider(props) {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { socket } = useContext(UserContext);

    function getCustomers() {
        fetch(process.env.REACT_APP_API_URL + '/customers?sort[label]=asc&include[user]=true&include[orderform][include][address]=true&limit=10000', { credentials: 'include' }) //pickupAddress
            .then(res => res.json())
            .then(res => {
                setCustomers(res)//.sort(sortByName)
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
                setLoading(false);
            });
        //check performance when populating invoices
    }

    useEffect(() => getCustomers(), []);

    const C = (c, customerObject) => {
        const temp = [...c]
        temp[temp.findIndex((e) => e.id === customerObject.id)] = customerObject;
        return temp;
    };

    useEffect(() => {
        function update(customerObject) {
            setCustomers(c => C(c, customerObject));
        }
        function newC(customerObject) {
            console.log('new customer in socket', customerObject);
            setCustomers((c) => [customerObject, ...c]);
        }
        function deleted(id) {
            console.log('new deleted in socket', id);
            setCustomers(c => c.filter((e) => e.id !== id));
        }

        socket.current.on('customer:new', newC);
        socket.current.on('customer:update', update);
        socket.current.on('customer:deleted', deleted);

        return () => {
            socket.current.off('customer:new', newC);
            socket.current.off('customer:update', update);
            socket.current.off('customer:deleted', deleted);
        };
    }, []);


    return (
        <CustomerContext.Provider
            value={{
                customers,
                setCustomers,
                loading,
                setLoading
            }}>
            {props.children}
        </CustomerContext.Provider>
    );
}
