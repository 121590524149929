import api from './api';

const addAddress = async (customer, address) => {
    return api('customerAddresses', 'POST', {
        customer_id: customer,
        formattedAddress: address.formattedAddress,
        street: address.street,
        streetLine2: address.streetLine2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        coordinates: address.coordinates,
    });
}

const getAddress = async (address) => {
    try {
        const result = await api('customerAddresses/google/' + address);
        return getAddressObj(result);
    }
    catch (e) {
        return null;
    }
}

const getAddressObj = (place) => {
    const {
        name,
        address_components,
        geometry: { location },
    } = place;
    const lat = (typeof location.lat === 'function') ? location.lat() : location.lat;
    const long = (typeof location.lng === 'function') ? location.lng() : location.lng;
    // const {
    // 	address_components: [
    // 		{
    // 			long_name: streetNumber
    // 		},
    // 		{
    // 			long_name: streetName
    // 		}, ,
    // 		{
    // 			long_name: city
    // 		}, ,
    // 		{
    // 			long_name: state
    // 		}, ,
    // 		{
    // 			long_name: zipCode
    // 		},
    // 	],
    // } = place;

    const streetNumber = address_components.find(c => c.types.includes('street_number'))?.long_name;
    const streetName = address_components.find(c => c.types.includes('route'))?.long_name || name;
    const city = address_components.find(c => c.types.includes('sublocality') || c.types.includes('locality'))?.long_name;
    const state = address_components.find(c => c.types.includes('administrative_area_level_1'))?.short_name;
    const zipCode = address_components.find(c => c.types.includes('postal_code'))?.long_name;

    return {
        street: (streetNumber ? streetNumber + ' ' : '') + streetName,
        city,
        state,
        zipCode,
        formattedAddress: (streetNumber ? streetNumber + ' ' : '') + streetName + ' ' + city + ', ' + state + ', ' + zipCode,
        coordinates: { lat: lat, long: long },
    };
}

export { addAddress, getAddress, getAddressObj };
