import React, { useState, useEffect, createContext, useContext, useRef } from 'react';
import { notify } from '../custom/Notification';
import { UserContext } from './UserContext';
import api from '../helpers/api';

export const TripContext = createContext();

export default function TripProvider(props) {
  const FETCH_LIMIT = 500;
  const { soundOn, socket } = useContext(UserContext);
  const [trips, setTrips] = useState([]);
  const [skip, setSkip] = useState(0);
  const [currTrip, setCurrTrip] = useState(null);
  const [tripLoading, setTripLoading] = useState(false);
  const eventListenerRef = useRef(false);
  const changes = useRef({ new: [], update: [], deleted: [] });

  function applyUpdates() {
    if (!(changes.current.new.length || changes.current.update.length || changes.current.deleted.length)) return; // to avoid an infinite loop
    if (eventListenerRef.current) return;

    console.log('in applyUpdates after checks')

    let temp = changes.current;
    changes.current = { new: [], update: [], deleted: [] };

    console.log(temp)

    /* new */
    if (temp.new.length) {
      setTrips((t) => addNewTrips(t, temp.new));
      for (let TripObject of temp.new) {
        console.log('new trip in socket', TripObject.orderNumber);
        // if (TripObject.dispatcher?.fullName === 'errands.nyc website') {
          notify(TripObject.id, soundOn);
        // }
      }
    }

    /* update */
    if (temp.update.length) {
      setTrips(t => getUpdatedTrips(t, temp.update));
    }

    /* deleted */
    if (temp.deleted.length) {
      setTrips(t => t.filter((e) => !temp.deleted.includes(e.id)));
    }
  }

  useEffect(() => {
    if (window.location.pathname !== `/dispatch`) return;
    let timeout;
    const handleEvent = (event) => {
      /* clear prev timeout */
      clearTimeout(timeout);
      eventListenerRef.current = true;
      /* set timeout */
      timeout = setTimeout(() => {
        eventListenerRef.current = false;
        applyUpdates();
      }, 5000);
    };

    document.addEventListener('click', handleEvent, true);
    document.addEventListener('keypress', handleEvent, true);

    return () => {
      document.removeEventListener('click', handleEvent, true);
      document.removeEventListener('keypress', handleEvent, true);
      clearTimeout(timeout);
    };
  }, [window.location.pathname]);

  function getTrips() {
    setTripLoading(true);
    api(`trips?NOT[status]=completed&include[driver]=true&include[customer][include][orderform]=true&include[tags]=true
      &include[timeline][orderBy][timestamp]=asc&include[pickup_zone]=true&include[dropoff_zone]=true
      &sort[orderNumber]=desc&limit=${FETCH_LIMIT}&skip=${trips.length}`
    )
      .then(res => {
        setTrips(t => [...t, ...res]);
        if (res.length === FETCH_LIMIT && trips.length < 10000)
          setSkip(s => s + FETCH_LIMIT);
        else console.log('fetched all', skip, trips.length + res.length)
        setTripLoading(false);
      })
      .catch(() => setTripLoading(false));
  }

  useEffect(() => {
    console.log(skip, trips.length);
    // setTimeout(getTrips, 1000);
    getTrips();
  }, [skip]);

  const getUpdatedTrips = (t, tripObjects) => {
    const temp = [...t];
    tripObjects.forEach((trip) => {
      let toUpdate = temp[temp.findIndex((e) => e.id === trip.id)];
      if (toUpdate) {
        /* trips updated from the billing page won't be in this list */
        const latestTimeline = trip.timeline[trip.timeline.length - 1];
        latestTimeline?.fields?.forEach((f, i) => {
          toUpdate[f] = trip[f];
        });
        toUpdate['timeline'] = trip['timeline'];
        temp[temp.findIndex((e) => e.id === trip.id)] = toUpdate;
      }
    });
    return temp;
  };

  const addNewTrips = (t, newTrips) => {
    newTrips = newTrips.filter(e => !(t.map(ee => ee.id)).includes(e.id));
    return [...newTrips, ...t];
  }

  useEffect(() => {
    function update(tripObjects) { changes.current = { ...changes.current, update: [...changes.current.update, ...tripObjects] }; applyUpdates(); }
    function newT(TripObject) { changes.current = { ...changes.current, new: [...changes.current.new, TripObject] }; applyUpdates(); }
    function deleted({ ids }) { changes.current = { ...changes.current, deleted: [...changes.current.deleted, ...ids] }; applyUpdates(); }

    socket.current.on('trip:new', newT);
    socket.current.on('trip:update', update);
    socket.current.on('trip:deleted', deleted);

    return () => {
      socket.current.off('trip:new', newT);
      socket.current.off('trip:update', update);
      socket.current.off('trip:deleted', deleted);
    };
  }, []);

  return (
    <TripContext.Provider
      value={{
        trips,
        setTrips,
        currTrip,
        setCurrTrip,
      }}>
      {props.children}
    </TripContext.Provider>
  );
}


