import React, { useState, useEffect, createContext, useContext } from 'react';
import { sortByName } from '../utils/functions';
import { UserContext } from './UserContext';

export const DriverContext = createContext();

export default function DriverProvider(props) {
    const [drivers, setDrivers] = useState([]);
    const [activeDrivers, setActiveDrivers] = useState([]);
    const { socket } = useContext(UserContext);

    function getDrivers() {
        fetch(process.env.REACT_APP_API_URL + '/drivers?include[user]=true&sort[label]=asc&limit=10000', { credentials: 'include' })
            .then(res => res.json())
            .then(res => setDrivers(res)) //.sort(sortByName)
            .catch((err) => { console.log(err); });
    }

    useEffect(() => getDrivers(), []);
    useEffect(() => setActiveDrivers(drivers.filter(e => e.active)), [drivers]); //.sort(sortByName)

    const D = (d, driverObject) => {
        const temp = [...d];
        temp[temp.findIndex((e) => e.id === driverObject.id)] = driverObject;
        return temp;
    };

    useEffect(() => {
        function update(driverObject) {
            setDrivers(d => D(d, driverObject));
        }
        function newD(driverObject) {
            setDrivers((d) => [driverObject, ...d]); //.sort(sortByName)
        }
        function deleted(id) {
            setDrivers(d => d.filter((e) => e.id !== id));
        }

        socket.current.on('driver:new', newD);
        socket.current.on('driver:update', update);
        socket.current.on('driver:deleted', deleted);

        return () => {
            socket.current.off('driver:new', newD);
            socket.current.off('driver:update', update);
            socket.current.off('driver:deleted', deleted);
        };
    }, []);

    return (
        <DriverContext.Provider
            value={{
                drivers,
                setDrivers,
                activeDrivers,
                setActiveDrivers
            }}>
            {props.children}
        </DriverContext.Provider>
    );
}