import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { statuses, paymentStatuses, packageTypes, billingSchedules, chargeTypes } from './sources';
import { BooleanCell, AddressCell, LinkCell, PhoneCell, LanguagesCell, VehiclesType, PhoneType } from '../custom/Cells';

const DASHBOARD_FIELDS = [
    //not in use
    { fieldname: 'driverID', header: 'Driver ID', data: 'driver__id', hidden: true },
    { fieldname: 'customerID', header: 'Customer ID', data: 'customer__id', hidden: true },
    { fieldname: 'driverName', header: 'Driver', data: 'driver_name' },
    { fieldname: 'customerName', header: 'Customer Name', data: 'customer_fullName', type: 'handsontable' },
    { fieldname: 'pickupName', header: 'Pickup Name', data: 'pickupName' },
    { fieldname: 'pickupPhone', header: 'Pickup Phone', data: 'pickupPhone' },
    { fieldname: 'pickupAddress', header: 'Pickup Address', data: 'pickupAddress_location_formattedAddress' },
    { fieldname: 'dropoffName', header: 'Dropoff Name', data: 'dropoffName' },
    { fieldname: 'dropoffPhone', header: 'Dropoff Phone', data: 'dropoffPhone' },
    { fieldname: 'dropoffAddress', header: 'Dropoff Address', data: 'dropoffAddress_location_formattedAddress' },
    { fieldname: 'status', header: 'Status', data: 'status', type: 'dropdown', source: statuses },
    { fieldname: 'paymentStatus', header: 'Paid', data: 'paymentStatus', type: 'dropdown', source: paymentStatuses },
    { fieldname: 'price', header: 'Price', data: 'price', type: 'currency' },
    { fieldname: 'tripScheduleTime', header: 'Scheduled Time', data: 'tripScheduleTime', type: 'formattedDate' },
    { fieldname: 'packageType', header: 'Package Type', data: 'packageType', type: 'dropdown', source: packageTypes },
    { fieldname: 'note', header: 'Notes', data: 'note' },
    { fieldname: 'dispatchbtn', header: '', data: 'btn', type: 'text', editor: false, renderer: 'html' },
];

const TRIPS_FIELDS = [
    //not in use
    // { fieldname: 'id', header: 'Trip ID', path: 'driver__id', readOnly: true, hidden: true },
    // { fieldname: 'driverID', header: 'Driver ID', path: 'driver__id', hidden: true, readOnly: true,  },
    { fieldname: 'driverName', header: 'Driver', path: 'driver_fullName', readOnly: true },
    // { fieldname: 'customerID', header: 'Customer ID', path: 'customer__id', hidden: true, readOnly: true,  ,readOnly: true},
    { fieldname: 'customerName', header: 'Customer Name', path: 'customer_fullName', readOnly: true },
    { fieldname: 'billingEmail', header: 'Cust. Bill. Email', path: 'customer_billingEmail', readOnly: true },
    {
        fieldname: 'customerCompanyName',
        header: 'Company Name',
        path: 'customer_companyName',
        hidden: true,
        readOnly: true,
    },
    { fieldname: 'customerPhoneNumber', header: 'Phone Number', path: 'customer_phoneNumber', readOnly: true },
    { fieldname: 'pickupName', header: 'Pickup Name', path: 'pickupName', hidden: true, readOnly: true },
    { fieldname: 'pickupPhone', header: 'Pickup Phone', path: 'pickupPhone', hidden: true, readOnly: true },
    {
        fieldname: 'pickupAddress',
        header: 'Pickup Address',
        path: 'pickupAddress_location_formattedAddress',
        readOnly: true,
    },
    { fieldname: 'dropoffName', header: 'Dropoff Name', path: 'dropoffName', hidden: true, readOnly: true },
    { fieldname: 'dropoffPhone', header: 'Dropoff Phone', path: 'dropoffPhone', hidden: true, readOnly: true },
    {
        fieldname: 'dropoffAddress',
        header: 'Dropoff Address',
        path: 'dropoffAddress_location_formattedAddress',
        readOnly: true,
    },
    {
        fieldname: 'status',
        header: 'Status',
        path: 'status',
        type: 'dropdown',
        source: statuses,
        readOnly: true,
    },
    {
        fieldname: 'paymentStatus',
        header: 'Paid',
        path: 'paymentStatus',
        type: 'dropdown',
        source: paymentStatuses,
        hidden: true,
        readOnly: true,
    },
    { fieldname: 'price', header: 'Price', path: 'price', type: 'currency', hidden: true, readOnly: true },
    {
        fieldname: 'tripScheduleTime',
        header: 'Scheduled Time',
        path: 'tripScheduleTime',
        type: 'date',
        hidden: true,
        readOnly: true,
    },
    { fieldname: '', header: 'Time Dispatched', path: '', readOnly: true },
    { fieldname: '', header: 'Time Delivered', path: '', readOnly: true },
    {
        fieldname: 'packageType',
        header: 'Package Type',
        path: 'packageType',
        type: 'dropdown',
        source: packageTypes,
        hidden: true,
        readOnly: true,
    },
    { fieldname: 'refToCreatedBy', header: 'Created By', path: 'refToCreatedBy_fullName', readOnly: true },
    { fieldname: 'note', header: 'Notes', path: 'note', readOnly: true },
];
const DRIVER_INFO_FIELDS = [
    { fieldname: 'fullName', Header: 'Name', accessor: 'fullName', required: true },
    { fieldname: 'email', Header: 'Email', accessor: 'user.email', type: 'email' },
    {
        fieldname: 'smsNumber',
        Header: 'Phone Number',
        accessor: 'smsNumber',
        type: 'tel',
        required: true,
        Cell: PhoneCell,
    },
];

const DRIVERS_FIELDS = [
    // { fieldname: 'driverID', Header: 'Driver ID', accessor: 'id', readOnly: true, hidden: true },
    { fieldname: 'fullName', Header: 'Name', accessor: 'fullName', required: true },
    { fieldname: 'email', Header: 'Email', accessor: 'user.email', type: 'email' },
    {
        fieldname: 'smsNumber',
        Header: 'Phone Number',
        accessor: 'smsNumber',
        type: 'tel',
        required: true,
        Cell: PhoneCell,
    },
    { fieldname: 'active', Header: 'Active', accessor: 'active', type: 'checkbox', new: false, Cell: BooleanCell },
    {
        fieldname: 'smsOptIn',
        Header: 'Text Notification',
        accessor: 'smsOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    {
        fieldname: 'notificationOptIn',
        Header: 'App Notifications',
        accessor: 'notificationOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    { fieldname: 'onDuty', Header: 'On Duty', accessor: 'onDuty', type: 'checkbox', new: false, Cell: BooleanCell },
];
const DRIVER_AVAILABILITY_FIELDS = [
    {
        fieldname: 'languages',
        Header: 'Languages',
        accessor: 'languages',
        type: 'languages',
        Cell: LanguagesCell,
    },
    {
        fieldname: 'vehicleTypes',
        Header: 'Vehicle Type',
        accessor: 'vehicleTypes',
        type: 'vehicleTypes',
        new: false,
        Cell: VehiclesType,
    },
    // {
    //     fieldname: 'phoneType',
    //     Header: 'Phone Type',
    //     accessor: 'phoneType',
    //     type: 'phoneType',
    //     new: false,
    //     Cell: PhoneType,
    // },
    {
        fieldname: 'sixthirteen',
        Header: '613',
        accessor: 'sixthirteen',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    { fieldname: 'onDuty', Header: 'On Duty', accessor: 'onDuty', type: 'checkbox', new: false, Cell: BooleanCell },
    { fieldname: 'active', Header: 'Active', accessor: 'active', type: 'checkbox', new: false, Cell: BooleanCell },
    {
        fieldname: 'smsOptIn',
        Header: 'Text Notification',
        accessor: 'smsOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
    {
        fieldname: 'notificationOptIn',
        Header: 'App Notifications',
        accessor: 'notificationOptIn',
        type: 'checkbox',
        new: false,
        Cell: BooleanCell,
    },
];

const CUSTOMERS_FIELDS = [
    // { fieldname: 'customerID', Header: 'Customer ID', accessor: 'id', readOnly: true, hidden: true },
    { fieldname: 'fullName', Header: 'Name', accessor: 'fullName', required: true, hidden: false },
    { fieldname: 'companyName', Header: 'Company Name', accessor: 'companyName', hidden: false },
    {
        fieldname: 'phoneNumber',
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        type: 'tel',
        required: true,
        Cell: PhoneCell,
    },
    { fieldname: 'billingEmail', Header: 'Billing Email', accessor: 'billingEmail', type: 'email' },
    { fieldname: 'orderform_websiteLink', Header: 'Website', accessor: 'orderform.websiteLink' },
    {
        fieldname: 'orderform_link',
        Header: 'Unique Link',
        accessor: 'orderform.link',
        type: 'linkField',
        link: process.env.REACT_APP_ORDER_URL,
        Cell: LinkCell,
    },
    // { fieldname: 'business', Header: 'Business', accessor: 'business', type: 'checkbox', Cell: BooleanCell, hidden: false },
    // { fieldname: 'chargeType', Header: 'Charge Type', accessor: 'chargeType', type: 'select', source: chargeTypes, hidden: true },
    // { fieldname: 'billingSchedule', Header: 'Billing Schedule', accessor: 'billingSchedule', type: 'select', source: billingSchedules },
];

const PAYMENT_FIELDS = [
    { fieldname: 'chargeType', Header: 'Charge Type', accessor: 'chargeType', type: 'select', source: chargeTypes },
    {
        fieldname: 'billingSchedule',
        Header: 'Billing Schedule',
        accessor: 'billingSchedule',
        type: 'select',
        source: billingSchedules,
    },
    {
        fieldname: 'chargeAutomatically',
        Header: 'Charge Automatically',
        accessor: 'chargeAutomatically',
        type: 'checkbox',
    },
    { fieldname: 'emailInvoice', Header: 'Email Invoice', accessor: 'emailInvoice', type: 'checkbox' },
    { fieldname: 'emailCSV', Header: 'Email CSV', accessor: 'emailCSV', type: 'checkbox' },
];

const ORDER_FIELDS = [
    {
        fieldname: 'orderform_address',
        Header: 'Address',
        accessor: 'orderform.address',
        type: 'address',
        Cell: AddressCell,
    },
    {
        fieldname: 'orderform_addressDisplay',
        Header: 'Address',
        accessor: 'orderform.address.formattedAddress',
        hidden: true,
    },
    { fieldname: 'orderform_addressLine2', Header: 'Address Line 2', accessor: 'orderform.address.streetLine2' },
    { fieldname: 'orderform_pickupName', Header: 'Pickup Name', accessor: 'orderform.pickupName' },
    { fieldname: 'orderform_numberOfPackages', Header: 'Quantity', accessor: 'orderform.numberOfPackages' },
    {
        fieldname: 'orderform_packageType',
        Header: 'Package Type',
        accessor: 'orderform.packageType',
        type: 'select',
        source: packageTypes,
    },
    { fieldname: 'orderform_note', Header: 'Note', accessor: 'orderform.note' },
    // { fieldname: 'orderform_logoLink', Header: 'Logo Link', accessor: 'orderform.logoLink', },
];

const INVOICES_FIELDS = [
    { fieldname: 'invoiceNumber', Header: 'Invoice Number', accessor: 'invoiceNumber' },
    { fieldname: 'customer', Header: 'Customer', accessor: 'customer.fullName' },
    // { fieldname: 'trips' },
    { fieldname: 'invoiceDate', Header: 'Date', accessor: 'invoiceDate' },
    { fieldname: 'total', Header: 'Total Amount', accessor: 'totalPrice', type: 'currency' },
    { fieldname: 'status', Header: 'Status', accessor: 'status' },
];

const TRIPS_SCHEMA = yupResolver(
    yup.object().shape({
        pickupName: yup.string().optional(),
        pickupPhone: yup.string().optional(),
        pickupAddress: yup.string().optional(),
        dropoffName: yup.string().optional(),
        dropoffPhone: yup.string().optional(),
        dropoffAddress: yup.string().optional(),
        status: yup.string().optional(),
        paymentStatus: yup.string().optional(),
        price: yup.number().positive().optional().nullable(),
        tripScheduleTime: yup.date().optional(),
        packageType: yup.string().optional(),
        note: yup.string().optional(),
    })
);

const DRIVERS_SCHEMA = yupResolver(
    yup.object().shape({
        firstName: yup.string().optional(),
        lastName: yup.string().optional(),
        smsNumber: yup
            .number()
            .positive()
            .integer()
            .optional()
            .nullable(true)
            .transform((val, originalVal) => (isNaN(val) ? null : val))
            .test('len', 'Must be exactly 10 characters', (val) => val === null || val.toString().length === 10),
    })
);

const CUSTOMERS_SCHEMA = yupResolver(
    yup.object().shape({
        firstName: yup.string().optional(),
        lastName: yup.string().optional(),
        companyName: yup.string().optional(),
        phoneNumber: yup
            .number()
            .typeError('Must be a number')
            .positive()
            .integer()
            .nullable(true)
            .transform((val, originalVal) => (originalVal.toString().length === 0 ? null : val))
            .test('len', 'Must be exactly 10 characters', (val) => val === null || val.toString().length === 10),
        billingEmail: yup.string().email('Must be a valid email').optional(),
    })
);

const TABLE_FIELDS = {
    TRIPS_FIELDS,
    DRIVERS_FIELDS,
    DRIVER_INFO_FIELDS,
    DRIVER_AVAILABILITY_FIELDS,
    CUSTOMERS_FIELDS,
    ORDER_FIELDS,
    INVOICES_FIELDS,
    PAYMENT_FIELDS,
};
const DETAIL_SCHEMA = {
    DRIVERS_SCHEMA: DRIVERS_SCHEMA,
    CUSTOMERS_SCHEMA: CUSTOMERS_SCHEMA,
    TRIPS_SCHEMA: TRIPS_SCHEMA,
};

export { TABLE_FIELDS, DASHBOARD_FIELDS, DETAIL_SCHEMA };
