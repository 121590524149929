import phone from "phone";
import React, { useState, useEffect } from "react";
import { Button, Container, Form, Alert, Table } from 'react-bootstrap'
import sampleCSV from "../assets/bulkUploadPage/sample.csv"
import { packageTypes } from '../constants/sources';
import { toast } from 'react-toastify';
import { getAddress } from "../helpers/addressHelper";
import api from '../helpers/api';

function BulkUpload({ handleClose }) {
    const [file, setFile] = useState();
    const [data, setData] = useState();
    const [error, setError] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    useEffect(() => {
        if (submitError)
            setTimeout(() => setSubmitError(null), 4000);
    }, [submitError])

    useEffect(() => {
        if (file) {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = function (e) {
                let trips = e.target.result.trim().split("\r\n");
                let headers = trips.shift().split(',').map(e => e.trim().toLowerCase().replace(/\W/g, '_'));

                for (let i = 0; i < trips.length; i++) {
                    trips[i] = trips[i].replace(/"[^"]*,?[^"]*"/g, t => t.replace(/,/g, '')).split(',');
                    const trip = {};
                    for (let j = 0; j < headers.length; j++) {
                        trip[headers[j]] = trips[i][j];
                    }
                    trips[i] = trip;

                    if (trip.pickup_phone === undefined || trip.pickup_phone === null || trip.pickup_phone === '') trip.pickup_phone = null;
                    else {
                        const phoneObject = phone(trip.pickup_phone, { country: 'USA' });
                        if (!phoneObject.phoneNumber) setError(err => [
                            ...err,
                            {
                                line: i + 2,
                                errors: 'Invalid',
                                field: 'Pickup Phone'
                            }
                        ]);
                        else trip.pickup_phone = phoneObject.phoneNumber;
                    }

                    if (trip.delivery_phone === undefined || trip.delivery_phone === null || trip.delivery_phone === '') trip.delivery_phone = null;
                    else {
                        const phoneObject = phone(trip.delivery_phone, { country: 'USA' });
                        if (!phoneObject.phoneNumber) setError(err => [
                            ...err,
                            {
                                line: i + 2,
                                errors: 'Invalid',
                                field: 'Dropoff Phone'
                            }
                        ]);
                        else trip.delivery_phone = phoneObject.phoneNumber;
                    }

                    if (!trip.pickup_address_line_1) {
                        setError(err => [
                            ...err,
                            {
                                line: i + 2,
                                errors: 'Missing',
                                field: 'Pickup Address Line 1'
                            }
                        ])
                    }
                    if (!trip.delivery_address_line_1) {
                        setError(err => [
                            ...err,
                            {
                                line: i + 2,
                                errors: 'Missing',
                                field: 'Delivery Address Line 1'
                            }
                        ])
                    }
                    if (!trip.package_type) {
                        setError(err => [
                            ...err,
                            {
                                line: i + 2,
                                errors: 'Missing',
                                field: 'Package Type'
                            }
                        ])
                    } else if (!packageTypes.find(p => p.value.toLowerCase() === trip.package_type.toLowerCase())) {
                        setError(err => [
                            ...err,
                            {
                                line: i + 2,
                                errors: 'Invalid Value',
                                field: 'Package Type'
                            }
                        ])
                    }
                    if (!trip.billing_phone) {
                        setError(err => [
                            ...err,
                            {
                                line: i + 2,
                                errors: 'Missing',
                                field: 'Billing Phone'
                            }
                        ])
                    }
                    if (trip.date) {
                        if (new Date(trip.date) < new Date()) {
                            setError(err => [
                                ...err,
                                {
                                    line: i + 1,
                                    errors: 'Invalid date',
                                    field: 'Date'
                                }
                            ])
                        }
                    }
                }
                console.log(trips);
                setData(trips)
            };
        }
    }, [file]);

    const handleOnChange = (e) => {
        setFile(e.target.files[0]);
        setError([]);
    }

    const getCustomer = async (billing_phone) => {
        let phone = billing_phone;
        if (billing_phone.length === 10) phone = '+1' + billing_phone;
        return fetch(process.env.REACT_APP_API_URL + `/customers?OR[0][phoneNumber]=${encodeURIComponent(phone)}&OR[1][billingEmail]=${encodeURIComponent(billing_phone)}&include[orderform][include][address]=true`,
            {
                method: 'GET',
                credentials: 'include',
            })
            .then((res) => res.json())
            .then((res) => res[0])
            .catch((err) => {
                console.log(err)
                return err;
            });
    }

    const getDriver = async (p) => {
        let phone = p?.replace(/[^\d\+]/g, '');
        return fetch(process.env.REACT_APP_API_URL + `/drivers?smsNumber=${phone}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((res) => res[0]?.id)
            .catch((err) => {
                console.log(err)
                return err;
            });
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const promises = data.map((d, i) =>
            new Promise((resolve, reject) => { post(d, i, resolve, reject) })
        );
        async function post(data, index, resolve, reject) {
            const customer = await getCustomer(data.billing_phone);
            if (!customer) reject('Invalid customer');
            const driverId = await getDriver(data.driver);

            let pickupAddress;
            if (data.pickup_address_line_1 === 'default') {
                pickupAddress = customer.orderform?.pickupAddress;
                if (!pickupAddress) {
                    reject(`Line ${(index + 2)}: Invalid Pickup Address`);
                }
            }
            else {
                pickupAddress = await getAddress(data.pickup_address_line_1);
                if (!pickupAddress) {
                    reject(`Line ${(index + 2)}: Pickup Address not found`);
                }
                pickupAddress.streetLine2 = data.pickup_address_line_2 || null;
            }

            const dropoffAddress = await getAddress(data.delivery_address_line_1);
            if (!dropoffAddress) {
                reject(`Line ${(index + 2)}: Delivery Address not found`);
            }
            dropoffAddress.streetLine2 = data.delivery_address_line_2 || null;

            data.date = (data.date === undefined || data.date === null || data.date === '') ? null : new Date(data.date);

            const newData = {
                customer_id: customer.id,
                dropoffPhone: data.delivery_phone,
                numberOfPackages: Number(data.number_of_packages) || 1,
                packageType: data.package_type,
                pickupName: data.pickup_name,
                pickupPhone: data.pickup_phone,
                dropoffName: data.delivery_name,
                note: data.note,
                price: Number(data.price) || null,
                pickupAddress: pickupAddress,
                dropoffAddress: dropoffAddress,
                driver_id: driverId,
                tripScheduleTime: data.date ?? null,
            }
            delete data.driver
            delete data.pickup_address_line_1
            delete data.pickup_address_line_2
            delete data.delivery_address_line_1
            delete data.delivery_address_line_2

            const trip = await api('trips', 'POST', newData)
                .catch(e => reject(e));
            // console.log(trip)
            resolve();
        }

        await Promise.all(promises)
            .then(() => {
                console.log("passed");
                setSubmitted(true);
                setTimeout(() => {
                    handleClose();
                }, "3000");
            })
            .catch(err => {
                setSubmitError(err);
            });
    }

    const copyErrors = () => {
        const content = document.querySelector('#bulkerrorstable').outerHTML;
        const blob = new Blob([content], { type: 'text/html' });
        const clipboardItem = new window.ClipboardItem({ 'text/html': blob });
        navigator.clipboard.write([clipboardItem]);
        toast.success('Errors copied to clipboard');
    }

    return (
        <Container>
            <div style={{ textAlign: "center" }}>
                <h1>Upload your csv file </h1>
                <div className='w-100 d-flex justify-content-center'>
                    <Form.Group controlId="formFileMultiple" className="mb-3 m-3 ">
                        <Form.Control type={"file"}
                            id={"csvFileInput"}
                            accept={".csv"}
                            onChange={handleOnChange} disabled={submitted} />
                    </Form.Group></div>
                <Button disabled={!file || error.length !== 0 || submitted} onClick={(e) => { handleSubmit(e) }}>Upload</Button>
                <hr />
                <div className="mt-2">{error.length > 0 && <>
                    <p className="text-dark fs-4">Errors found, please fix and try again</p>
                    <Button className="mb-3" variant='outline-dark' onClick={copyErrors}>Copy errors</Button>
                    <div style={{ height: '300px', overflowY: 'auto' }}>
                        <Table id='bulkerrorstable'>
                            <thead><tr >
                                <th>line</th>
                                <th>field</th>
                                <th>error</th>
                                <th className="error-action-cell">action</th>
                            </tr>
                            </thead>
                            {error.map(e => {
                                return <>
                                    {/* <div className="text-danger mb-1">{e.errors} {e.field} on line {e.line}</div> */}
                                    <tbody>
                                        <tr>
                                            <td style={{ backgroundColor: 'lightblue', padding: '4px' }}>{e.line}</td>
                                            <td style={{ padding: '4px' }}>{e.field}</td>
                                            <td style={{ backgroundColor: 'rgba(243, 32, 19)', color: 'white', padding: '4px' }}>{e.errors}</td>
                                            <td style={{ padding: '4px' }}>--</td>
                                        </tr>
                                    </tbody>
                                </>
                            })} </Table>
                    </div>
                </>}
                </div>
                <div className='mt-5'>
                    <a href={sampleCSV} className="text-secondary" style={{ textDecoration: "underline" }} download>Download sample</a>
                </div>
                {submitted && <Alert variant="success">
                    Your file has been successfully uploaded
                </Alert>}
                {submitError && <Alert variant="danger">
                    {submitError || "error uploading, please try again"}
                </Alert>}
            </div>
        </Container>
    );
}

export default BulkUpload